<template>
  <v-card-text class="diagram">
    <loader v-if="loading"></loader>
    <div class="create-btn">
      <v-btn small color="success" @click="openCreateModal = true"
        >Добавить характеристику</v-btn
      >
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Название</th>
          <th>Значения</th>
          <th>Группы</th>
          <th>Категории</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in FEATURES" :key="item.id">
          <td>{{ item.feature.name }}</td>
          <td>
            <div v-if="showAllValues !== item.values[0]" class="d-inline">
              <span
                v-for="(value, index) in item.values.slice(0, 5)"
                :key="value"
                >{{ value
                }}{{ index + 1 !== item.values.length ? ", " : "" }}</span
              >
            </div>
            <div v-else>
              <span v-for="(value, index) in item.values" :key="value"
                >{{ value
                }}{{ index + 1 !== item.values.length ? ", " : "" }}</span
              >
            </div>
            <div v-if="showAllValues !== item.values[0]" class="d-inline">
              <v-btn
                v-if="item.values.length > 5"
                icon
                color="primary"
                @click="showAllValues = item.values[0]"
                ><v-icon>mdi-dots-horizontal</v-icon></v-btn
              >
            </div>
          </td>
          <td>
            <span v-for="(group, index) in item.featureGroups" :key="group.id"
              >{{ group.name
              }}{{ index + 1 !== item.featureGroups.length ? ", " : "" }}</span
            >
          </td>
          <td>
            <span v-for="(cat, index) in item.categories" :key="cat.id"
              >{{ cat.name
              }}{{ index + 1 !== item.categories.length ? ", " : "" }}</span
            >
          </td>
          <td class="table-btns">
            <v-btn icon color="primary" @click="enableEditMode(item)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click="
                deletedFeatureId = item.feature.id;
                showDeleteModal = true;
              "
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <DeleteDialog
      :show="showDeleteModal"
      @close-modal="showDeleteModal = !showDeleteModal"
      @delete-modal="deleteFeature()"
    />
    <v-dialog v-model="openCreateModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          {{ editMode ? "Изменить" : "Создать" }}
          характеристику
        </v-card-title>
        <v-card-text>
          <v-form ref="featureForm">
            <v-text-field
              v-model="payload.name"
              :rules="[$validate.required]"
              label="Название"
              type="text"
              required
              clearable
            ></v-text-field>
            <v-autocomplete
              v-model="payload.categoryIds"
              :items="CATEGORY"
              item-text="name"
              item-value="id"
              no-data-text="Ничего не найдено"
              class="mt-3"
              label="Выберите категории"
              clearable
              multiple
            />
            <v-autocomplete
              v-model="payload.featureGroupId"
              :items="GROUPS"
              item-text="featureGroup.name"
              item-value="featureGroup.id"
              no-data-text="Ничего не найдено"
              class="mt-3"
              label="Выберите группу"
              clearable
            />
            <v-textarea
              v-model="payload.values"
              :rules="[$validate.required]"
              label="Варианты характеристики"
              hint="Ввод через запятую"
              auto-grow
              clearable
            ></v-textarea>
            <v-checkbox
              v-model="payload.isMain"
              :label="editMode ? 'Основная характеристика' : 'Сделать основной'"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openCreateModal = false">Закрыть</v-btn>
          <v-btn
            color="primary"
            @click="editMode ? editFeature() : createFeature()"
            >{{ editMode ? "Изменить" : "Создать" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import Loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import ShowMessage from "@/Functions/message";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
export default {
  name: "Features",
  components: {
    Loader,
    DeleteDialog,
  },
  props: {},
  data() {
    return {
      loading: false,
      showDeleteModal: false,
      deletedFeatureId: null,
      openCreateModal: false,
      payload: {
        isMain: false,
      },
      editMode: false,
      showAllValues: 0,
    };
  },
  computed: {
    ...mapGetters({
      FEATURES: "Features/FEATURES",
      GROUPS: "Features/GROUPS",
      CATEGORY: "Category/ALL_CATEGORY",
      DEPARTMENT: "Department/STATE",
    }),
  },
  watch: {
    openCreateModal(val) {
      if (!val) {
        this.payload = {};
        this.editMode = false;
        this.$refs.featureForm.resetValidation();
      }
    },
  },
  mounted() {
    if (!this.FEATURES.length) {
      this.setAll();
    }
    this.setCategory();
  },
  methods: {
    ...mapActions({
      GET_ALL_FEATURES: "Features/GET_ALL_FEATURES",
      GET_DEPARTMENT: "Department/GET_DEPARTMENT",
      GET_ALL_CATEGORY: "Category/GET_ALL_CATEGORY",
    }),
    getValues(arr) {
      let newArr = arr.slice(0, 5);
      if (arr.values[0] === this.showAllValues) {
        newArr = arr;
      }
      return newArr;
    },
    async setAll() {
      this.loading = true;
      await this.GET_ALL_FEATURES();
      this.loading = false;
    },
    async deleteFeature() {
      let response = await api.Features.deleteFeature(this.deletedFeatureId);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Характеристика удалена", true);
        this.setAll();
      }
      this.showDeleteModal = false;
    },
    async createFeature() {
      if (!this.$refs.featureForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.payload.values = this.payload.values.split(",");
      let response = await api.Features.createFeature(this.payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Характеристика создана", true);
        this.setAll();
      }
      this.openCreateModal = false;
    },
    async setCategory() {
      await this.GET_DEPARTMENT(this.$root.city);
      if (!this.CATEGORY.length) {
        await this.GET_ALL_CATEGORY(this.DEPARTMENT.cityDepartment[0].id);
      }
    },
    enableEditMode(item) {
      this.openCreateModal = true;
      this.editMode = true;
      this.payload.name = item.feature.name;
      this.payload.values = item.values.join(",");
      this.payload.featureGroupId = item.featureGroups.length
        ? item.featureGroups[0].id
        : 0;
      this.payload.categoryIds = item.categories.map((cat) => cat.id);
      this.payload.id = item.feature.id;
      this.payload.isMain = item.isMain;
    },

    async editFeature() {
      if (!this.payload.name) {
        ShowMessage("Укажите название");
        return;
      }
      this.payload.values = this.payload.values.split(",");
      let response = await api.Features.editFeature(this.payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Характеристика изменена", true);
        this.setAll();
      }
      this.openCreateModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-btns {
  width: 110px;
}
.create-btn {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>
