<template>
  <v-card-text class="diagram">
    <loader v-if="loading"></loader>
    <div class="create-btn">
      <v-btn small color="success" @click="openCreateModal = true"
        >Добавить группу</v-btn
      >
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Название</th>
          <th>Категории</th>
          <th>Характеристики</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in GROUPS" :key="item.featureGroup.id">
          <td>{{ item.featureGroup.name }}</td>
          <td>
            <span
              v-for="(gategory, index) in item.categories"
              :key="gategory.id"
              >{{ gategory.name
              }}{{ index + 1 !== item.categories.length ? ", " : "" }}
            </span>
          </td>
          <td>
            <div v-if="item.features">
              <div v-if="showAllValues !== item.features[0]" class="d-inline">
                <span
                  v-for="(feature, index) in item.features.slice(0, 5)"
                  :key="feature.id"
                  >{{ feature.name
                  }}{{ index + 1 !== item.features.length ? ", " : "" }}</span
                >
              </div>
              <div v-else>
                <span
                  v-for="(feature, index) in item.features"
                  :key="feature.id"
                  >{{ feature.name
                  }}{{ index + 1 !== item.features.length ? ", " : "" }}</span
                >
              </div>
              <div v-if="showAllValues !== item.features[0]" class="d-inline">
                <v-btn
                  v-if="item.features.length > 5"
                  icon
                  color="primary"
                  @click="showAllValues = item.features[0]"
                  ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                >
              </div>
            </div>
          </td>
          <td class="table-btns">
            <v-btn icon color="primary" @click="enableEditMode(item)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click="
                replaceData.id = item.featureGroup.id;
                openReplaceModal = true;
              "
            >
              <v-icon>mdi-reload-alert</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="openReplaceModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Заменить группу характеристик
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="replaceData.newId"
            :items="GROUPS"
            item-text="featureGroup.name"
            item-value="featureGroup.id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Выберите группу"
            clearable
          />
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openReplaceModal = false">Закрыть</v-btn>
          <v-btn color="primary" @click="replaceGroup()">Заменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openCreateModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          {{ editMode ? "Изменить" : "Создать" }}
          группу характеристик
        </v-card-title>
        <v-card-text>
          <v-form ref="groupForm">
            <v-text-field
              v-model="newGroup.name"
              :rules="[$validate.required]"
              label="Название"
              type="text"
              required
              clearable
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openCreateModal = false">Закрыть</v-btn>
          <v-btn
            color="primary"
            @click="editMode ? editGroup() : createGroup()"
            >{{ editMode ? "Изменить" : "Создать" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import Loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
export default {
  name: "Features",
  components: {
    Loader,
  },
  props: {},
  data() {
    return {
      loading: false,
      openCreateModal: false,
      openReplaceModal: false,
      showAllValues: 0,
      newGroup: {},
      editMode: false,
      replaceData: {},
    };
  },
  computed: {
    ...mapGetters({
      GROUPS: "Features/GROUPS",
    }),
  },
  watch: {
    openCreateModal(val) {
      if (!val) {
        this.newGroup = {};
        this.editMode = false;
        this.$refs.groupForm.resetValidation();
      }
    },
  },
  mounted() {
    if (!this.GROUPS.length) {
      this.setAll();
    }
  },
  methods: {
    ...mapActions({
      GET_ALL_GROUPS: "Features/GET_ALL_GROUPS",
    }),
    async setAll() {
      this.loading = true;
      await this.GET_ALL_GROUPS();
      this.loading = false;
    },
    async replaceGroup() {
      if (!this.replaceData.newId) {
        ShowMessage("Выберите группу для замены");
        return;
      }
      let response = await api.Features.replaceGroup(this.replaceData);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Группа заменена", true);
        this.setAll();
      }
      this.openReplaceModal = false;
    },
    async createGroup() {
      if (!this.$refs.groupForm.validate()) {
        ShowMessage("Укажите название");
        return;
      }
      let response = await api.Features.createGroup({
        name: this.newGroup.name,
      });
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Группа создана", true);
        this.setAll();
      }
      this.openCreateModal = false;
    },
    enableEditMode(group) {
      this.openCreateModal = true;
      this.editMode = true;
      this.newGroup.name = group.featureGroup.name;
      this.newGroup.id = group.featureGroup.id;
    },
    async editGroup() {
      if (!this.$refs.groupForm.validate()) {
        ShowMessage("Укажите название");
        return;
      }
      let response = await api.Features.editGroup({
        id: this.newGroup.id,
        body: { name: this.newGroup.name },
      });
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Группа изменена", true);
        this.setAll();
      }
      this.openCreateModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-btns {
  width: 110px;
}
.create-btn {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>
